export const environment = {
  production: false,
  host: 'https://leapbe.asharami-innovations.io/',
  businessId: '743b7b83c026caad04b0',
  customerPortalBaseURL: 'https://customer-test.pays-app.co/',
  termsAndConditions:
    'https://ai-pro-platform-receipts-bucket.s3.amazonaws.com/Asharami+Innovations+Terms_',
  faroName: 'Leap',
  faroUrl:
    'https://faro-collector-dev-us-central-0.grafana.net/collect/c7fc34f20b6581167d0ca36268c0d26e',
  paysApiKey: '6f86b0350a37edefb31d8416638b94ff',
  productId_NMD: '1',
  productId_MD: '2',
};
