import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpInterceptor,
  HttpErrorResponse,
  HttpEvent,
} from '@angular/common/http';
import { Observable, catchError, throwError } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private toastr: ToastrService) {}

  intercept(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    request: HttpRequest<any>,
    next: HttpHandler
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        let errorMessage =
          error.error?.message || error.statusText || 'An error occurred';

        if (errorMessage === 'Unknown Error') {
          errorMessage = 'An error occurred. Kindly try again in a few minutes';
        }

        if (
          !request.url.includes('/aip/verifyPayment') &&
          !request.url.includes('/aip/referral')
        ) {
          this.toastr.error(errorMessage);
        }
        return throwError(error);
      })
    );
  }
}
