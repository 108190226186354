import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent {
  @Input() type: 'button' | 'submit' | 'reset' = 'button';

  @Input() color:
    | 'primary'
    | 'outline'
    | 'secondary'
    | 'transparent'
    | 'transparent-gray' = 'primary';

  @Input() loading = false; // Added loading input

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Output() clicked = new EventEmitter<any>();

  get classes(): string {
    let buttonClasses = '';
    switch (this.color) {
      case 'transparent':
        buttonClasses = `inline-block px-6 py-2 rounded font-medium rounded
        bg-transparent focus:outline-none h-14 w-full text-base
        text-primary`;
        break;
      case 'transparent-gray':
        buttonClasses = `inline-block px-6 py-2 rounded font-medium rounded
        bg-transparent focus:outline-none h-14 w-full text-base
        text-gray-600`;
        break;
      case 'outline':
        buttonClasses = `inline-block px-6 py-2 rounded font-medium rounded
        bg-white focus:outline-none h-14 w-full text-base
        text-primary border border-primary`;
        break;
      case 'primary':
        buttonClasses = `inline-block px-6 py-2 rounded font-medium rounded
        bg-primary focus:outline-none h-14 w-full text-base
        text-white`;
        break;
      case 'secondary':
        buttonClasses = `inline-block px-6 py-2 rounded font-medium rounded
        bg-primary-100 focus:outline-none h-14 w-full text-base
        text-primary`;
        break;
    }
    return buttonClasses;
  }

  onClick() {
    this.clicked.emit();
  }
}
