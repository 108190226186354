<div class="flex w-full items-center rounded border bg-textInput">
  <!-- Country Code Select -->
  <div *ngIf="type === 'phone'">
    <div class="relative inline-flex flex-col border-r">
      <span
        class="rounded-lg border-none bg-transparent py-2 pl-4 pr-3 text-base">
        +234
      </span>
    </div>
  </div>

  <!-- Input -->
  <div class="ml-2 flex-grow">
    <div class="flex items-center pr-3">
      <input
        class="block w-full appearance-none rounded border border-none bg-transparent p-4 focus:border-transparent focus:outline-none"
        [type]="inputType"
        [id]="id"
        [name]="name"
        [placeholder]="placeholder"
        [required]="required"
        [formControlName]="formControlName" />
      <ng-content></ng-content>
    </div>
  </div>
</div>
