import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { trigger } from '@angular/animations';

@Component({
  selector: 'app-pill-tab',
  templateUrl: './pill.component.html',
  styleUrls: ['./pill.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [trigger('tabAnimation', [])],
})
export class PillComponent {
  @Input() tabs: string[] = [];

  @Input() active = 0;

  @Output() selectedTabIndex = new EventEmitter<number>();

  onTabSelect(event: MouseEvent, index: number) {
    event.preventDefault();
    this.active = index;
    this.selectedTabIndex.emit(index);
  }
}
