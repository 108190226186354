import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { Country } from 'src/app/interfaces/location';

@Injectable({
  providedIn: 'root',
})
export class CountriesService {
  private countriesUrl =
    'https://restcountries.com/v3.1/all?fields=name,flags,idd';

  constructor(private http: HttpClient) {}

  getCountries(): Observable<Country[]> {
    return this.http.get<Country[]>(this.countriesUrl);
  }
}
