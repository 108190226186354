import { Country } from '../interfaces/location';

export class Countries {
  static nigeria: Country = {
    name: {
      common: 'Nigeria',
      official: 'Federal Republic of Nigeria',
      nativeName: {
        eng: {
          official: 'Federal Republic of Nigeria',
          common: 'Nigeria',
        },
      },
    },
    tld: ['.ng'],
    cca2: 'NG',
    ccn3: '566',
    cca3: 'NGA',
    cioc: 'NGR',
    independent: true,
    status: 'officially-assigned',
    unMember: true,
    currencies: {
      nGN: {
        name: 'Nigerian Naira',
        symbol: '₦',
      },
    },
    idd: {
      root: '+2',
      suffixes: ['34'],
    },
    capital: ['Abuja'],
    altSpellings: ['NG', 'Nijeriya', 'Naíjíríà', 'Federal Republic of Nigeria'],
    region: 'Africa',
    subregion: 'Western Africa',
    languages: {
      eng: 'English',
    },
    translations: {
      ara: {
        official: 'جمهورية نيجيريا الفدرالية',
        common: 'نيجيريا',
      },
      bre: {
        official: 'Federal Republic of Nigeria',
        common: 'Nigeria',
      },
      ces: {
        official: 'Nigerijská federativní republika',
        common: 'Nigérie',
      },
      cym: {
        official: 'Gweriniaeth Ffederal Nigeria',
        common: 'Nigeria',
      },
      deu: {
        official: 'Bundesrepublik Nigeria',
        common: 'Nigeria',
      },
      est: {
        official: 'Nigeeria Liitvabariik',
        common: 'Nigeeria',
      },
      fin: {
        official: 'Nigerian liittotasavalta',
        common: 'Nigeria',
      },
      fra: {
        official: 'République fédérale du Nigeria',
        common: 'Nigéria',
      },
      hrv: {
        official: 'Savezna Republika Nigerija',
        common: 'Nigerija',
      },
      hun: {
        official: 'Nigéria',
        common: 'Nigéria',
      },
      ita: {
        official: 'Repubblica federale di Nigeria',
        common: 'Nigeria',
      },
      jpn: {
        official: 'ナイジェリア連邦共和国',
        common: 'ナイジェリア',
      },
      kor: {
        official: '나이지리아 연방 공화국',
        common: '나이지리아',
      },
      nld: {
        official: 'Federale Republiek Nigeria',
        common: 'Nigeria',
      },
      per: {
        official: 'جمهوری فدرال نیجریه',
        common: 'نیجریه',
      },
      pol: {
        official: 'Federalna Republika Nigerii',
        common: 'Nigeria',
      },
      por: {
        official: 'República Federal da Nigéria',
        common: 'Nigéria',
      },
      rus: {
        official: 'Федеративная Республика Нигерия',
        common: 'Нигерия',
      },
      slk: {
        official: 'Nigérijská federatívna republika',
        common: 'Nigéria',
      },
      spa: {
        official: 'República Federal de Nigeria',
        common: 'Nigeria',
      },
      srp: {
        official: 'Savezna Republika Nigerija',
        common: 'Nigerija',
      },
      swe: {
        official: 'Förbundsrepubliken Nigeria',
        common: 'Nigeria',
      },
      tur: {
        official: 'Nijerya Federal Cumhuriyeti',
        common: 'Nijerya',
      },
      urd: {
        official: 'وفاقی جمہوریہ نائجیریا',
        common: 'نائجیریا',
      },
      zho: {
        official: '尼日利亚联邦共和国',
        common: '尼日利亚',
      },
    },
    latlng: [10, 8],
    landlocked: false,
    borders: ['BEN', 'CMR', 'NER', 'TCD'],
    area: 923768,
    demonyms: {
      eng: {
        f: 'Nigerian',
        m: 'Nigerian',
      },
      fra: {
        f: 'Nigériane',
        m: 'Nigérian',
      },
    },
    flag: '🇳🇬',
    maps: {
      googleMaps: 'https://goo.gl/maps/example',
      openStreetMaps: 'https://www.openstreetmap.org/relation/example',
    },
    population: 200963599,
    gini: {},
    fifa: 'NGA',
    car: {
      signs: ['WAN'],
      side: 'right',
    },
    timezones: ['UTC+01:00'],
    continents: ['AF'],
    flags: {
      png: 'https://example.com/nigeria.png',
      svg: 'https://example.com/nigeria.svg',
      alt: 'Flag of Nigeria',
    },
    coatOfArms: {
      png: 'https://example.com/coatofarms.png',
      svg: 'https://example.com/coatofarms.svg',
    },
    startOfWeek: 'monday',
    capitalInfo: {
      latlng: [9.072264, 7.491302],
    },
    postalCode: {
      format: 'NNNNNN',
      regex: '^\\d{6}$',
    },
  };
}
