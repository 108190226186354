import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-welcome-message',
  standalone: true,
  imports: [CommonModule, MatIconModule],
  templateUrl: './welcome-message.component.html',
})
export class WelcomeMessageComponent {
  @Input() imageUrl!: string;

  constructor(public dialogRef: MatDialogRef<WelcomeMessageComponent>) {}

  // Method to close the modal
  closeModal(): void {
    this.dialogRef.close(); // Close the dialog
  }
}
