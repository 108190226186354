import { Component } from '@angular/core';
import { IconRegistryService } from './services/tools/icon-registry.service';
import { MatDialog } from '@angular/material/dialog';
import { WelcomeMessageComponent } from './components/welcome-message/welcome-message.component';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'leap-fe';

  constructor(
    private iconService: IconRegistryService,
    private dialog: MatDialog
  ) {
    this.iconService.setIcons();
    // this.showWelcomeMessage();
  }

  welcomeImageUrl =
    'https://ai-pro-platform-receipts-bucket.s3.amazonaws.com/MicrosoftTeams-image+(1).png';

  showWelcomeMessage() {
    const dialogRef = this.dialog.open(WelcomeMessageComponent, {
      width: '500px',
    });
    dialogRef.componentInstance.imageUrl = this.welcomeImageUrl;
  }
}
