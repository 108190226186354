import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-iframe-dialog',
  templateUrl: './iframe-dialog.component.html',
})
export class IframeDialogComponent implements OnInit, AfterViewInit {
  @ViewChild('aipCheckoutIFrame') el!: ElementRef;

  public iframeSrc = new Subject<string>();

  public iframeSrcUrl$ = this.iframeSrc.asObservable();

  url!: string;

  urlSafe!: SafeResourceUrl;

  success = false;

  constructor(
    public sanitizer: DomSanitizer,
    private modal: MatDialogRef<IframeDialogComponent>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.url = data?.url;
  }

  ngOnInit(): void {
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
  }

  ngAfterViewInit() {
    console.warn('Now watching iFrame src url observable');

    window.addEventListener(
      'message',
      e => {
        if (e.data === true) {
          this.closeModal(e.data);
        }
      },
      false
    );

    this.iframeSrcUrl$.subscribe(data => {
      if (this.iframeSrcData.includes('https://asharami-innovations.io')) {
        console.warn('WIll close modal here ++++ >>> ');
        this.closeModal({
          result: {
            data: data,
            modal: this.data.modal,
            status: 'closed',
            message: 'Successful',
          },
        });
      }
    });
  }

  updateIframeSrcUrl(iframeSrcUrl: string) {
    console.warn('updateIframeSrcUrl called ------  ', iframeSrcUrl);
    this.iframeSrc.next(iframeSrcUrl);
  }

  get iframeSrcData() {
    const iframeSrcUrl = this.el ? new URL(this.el.nativeElement.src) : '';
    if (iframeSrcUrl !== '') {
      return iframeSrcUrl.href;
    } else {
      return '';
    }
  }

  @HostListener('window:message', ['$event'])
  successFunction(e: MessageEvent) {
    if (e?.data?.event === 'success' && e?.data?.data?.status === 'success') {
      this.success = true;
      this.iframeSrc.next('');
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  closeModal(data?: any) {
    if (data) {
      this.modal.close(data);
    } else {
      this.modal.close();
    }
  }
}
