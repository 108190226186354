import {
  Component,
  Input,
  Output,
  EventEmitter,
  ViewEncapsulation,
  OnInit,
  ViewChild,
  ElementRef,
  AfterViewInit,
} from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import {
  MatFormFieldAppearance,
  SubscriptSizing,
} from '@angular/material/form-field';
import { MatMenuTrigger } from '@angular/material/menu';
// import { MatSelectChange } from '@angular/material/select';
import { AccountInfo } from 'src/app/interfaces/account-info';

@Component({
  selector: 'app-select-search-input',
  templateUrl: './select-search-input.component.html',
  styleUrls: ['./select-search-input.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SelectSearchInputComponent implements OnInit, AfterViewInit {
  @ViewChild(MatMenuTrigger) selectDropdown!: MatMenuTrigger;

  @ViewChild('menuTrigger') menuTrigger!: ElementRef;

  @ViewChild('textInputContainer') textInputContainer!: ElementRef;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() accountInfos: AccountInfo[] = [];

  @Input() appearance: MatFormFieldAppearance = 'outline';

  @Input() subscriptSizing: SubscriptSizing = 'dynamic';

  @Input() title = '';

  @Input() labelField = 'label';

  @Input() valueField = 'value';

  @Input() placeholder = '';

  // @Input() searchControl: FormControl = new FormControl();

  @Input() formControlName!: string;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Output() selectionChange = new EventEmitter<any>();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Output() valueChange = new EventEmitter<AccountInfo>();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Output() deleteHistory = new EventEmitter<any>();

  selectedOption!: null | AccountInfo;

  $options: AccountInfo[] = [];

  _options: AccountInfo[] = [];

  noEntriesFoundLabel = '';

  showEnteredNumberOption = false;

  searchInput!: string;

  form: FormGroup;

  dropdownIcon = 'keyboard_arrow_down';

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  // compareFn = (o1: any, o2: any) =>
  //   o1 && o2 ? o1[this.valueField] === o2[this.valueField] : o1 === o2;

  constructor(private formBuilder: FormBuilder) {
    this.form = this.formBuilder.group({
      search: [''],
    });
  }

  ngOnInit() {
    this.selectedOption = null;
    this.$options = this.accountInfos;
    // this.searchControl.valueChanges.subscribe(value => {
    //   this.showEnteredNumberOption = value ? true : false;
    //   this.searchAccounts(value);
    // });
    this.form.controls['search'].valueChanges.subscribe(value => {
      this.selectionChange.emit({
        accountNo: value,
        accountName: '',
        accountType: '',
      });
    });
  }

  ngAfterViewInit(): void {
    // this.textInput.nativeElement.focus();
    this.selectDropdown.menuOpened.subscribe(() => {
      // setTimeout(() => {
      //   this.textInput.nativeElement.focus();
      // }, 600);
      this.dropdownIcon = 'keyboard_arrow_up';
    });
    this.selectDropdown.menuClosed.subscribe(() => {
      // setTimeout(() => {
      //   this.textInput.nativeElement.focus();
      // }, 600);
      this.dropdownIcon = 'keyboard_arrow_down';
    });
  }

  // onSelectionChange(event: MatSelectChange) {
  //   this._options = this.$options;
  //   let account: AccountInfo;
  //   if (event.value) {
  //     account = event.value;
  //   } else {
  //     account = {
  //       accountNo: this.searchControl.value,
  //       accountName: '',
  //       accountType: '',
  //     };
  //   }
  //   this.selectedOption = account;
  //   this.searchControl.setValue(account);
  //   this.selectionChange.emit(account);
  // }

  get placeholderLabel(): string {
    return 'Enter ' + this.placeholder;
  }

  // searchAccounts(event: string) {
  //   if (event) {
  //     const searchResult = this.$options.filter(option =>
  //       option.accountNo.startsWith(this.searchControl.value)
  //     );
  //     this._options = searchResult;
  //   } else {
  //     this._options = this.$options;
  //   }
  // }

  // selectOption() {
  //   const account: AccountInfo = {
  //     accountNo: this.searchControl.value,
  //     accountName: '',
  //     accountType: '',
  //   };
  //   this.selectedOption = account;
  //   this.selectionChange.emit(account);
  // }

  _deleteHistory() {
    this.deleteHistory.emit();
    this.$options = this.accountInfos;
    this.selectedOption = null;
  }

  _toggleDropdownMenu() {
    if (this.selectDropdown.menuOpen) {
      this._closeDropdownMenu();
    } else {
      this._openDropdownMenu();
    }
  }

  _openDropdownMenu() {
    this.selectDropdown.openMenu();
  }

  _closeDropdownMenu() {
    this.selectDropdown.closeMenu();
  }

  get dropdownWidth(): object {
    if (this.menuTrigger) {
      return {
        width: `${this.menuTrigger.nativeElement.offsetWidth - 0}px`,
      };
    } else return {};
  }

  get isDropdownOpen(): boolean {
    return this.selectDropdown.menuOpen;
  }

  selectOption(option: AccountInfo) {
    this.form.controls['search'].patchValue(option.accountNo);
    this.selectionChange.emit(option);
  }
}
