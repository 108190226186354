import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonComponent } from './button/button.component';
import { TextInputComponent } from './input/text-input/text-input.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SelectSearchInputComponent } from './input/select-search-input/select-search-input.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { PillComponent } from './tab/pill/pill.component';
import { ListComponent } from './list/list.component';
import { IframeDialogComponent } from './iframe/iframe-dialog/iframe-dialog.component';
import { SharedModule } from '../shared/shared.module';
import { MatMenuModule } from '@angular/material/menu';

@NgModule({
  declarations: [
    ButtonComponent,
    TextInputComponent,
    SelectSearchInputComponent,
    PillComponent,
    ListComponent,
    IframeDialogComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMatSelectSearchModule,
    MatSelectModule,
    MatIconModule,
    MatTabsModule,
    SharedModule,
    MatMenuModule,
  ],
  exports: [
    ButtonComponent,
    TextInputComponent,
    SelectSearchInputComponent,
    PillComponent,
    ListComponent,
    ReactiveFormsModule,
    FormsModule,
  ],
})
export class ComponentsModule {}
