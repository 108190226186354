import {
  Component,
  Input,
  Optional,
  Self,
  ViewEncapsulation,
} from '@angular/core';
import { CountriesService } from 'src/app/services/location/countries.service';
import { Country } from 'src/app/interfaces/location';
import {
  ControlContainer,
  ControlValueAccessor,
  FormControl,
  FormGroupDirective,
  NgControl,
} from '@angular/forms';
import { Countries } from 'src/app/constants/countries';

export const NOOP_VALUE_ACCESSOR: ControlValueAccessor = {
  writeValue(): void {},
  registerOnChange(): void {},
  registerOnTouched(): void {},
};

@Component({
  selector: 'app-text-input',
  templateUrl: './text-input.component.html',
  styleUrls: ['./text-input.component.scss'],
  encapsulation: ViewEncapsulation.None,
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    },
  ],
})
export class TextInputComponent {
  @Input()
  label!: string;

  @Input()
  type: 'text' | 'phone' | 'number' | 'email' = 'text';

  @Input()
  id!: string;

  @Input()
  name!: string;

  @Input()
  placeholder!: string;

  @Input()
  required!: boolean;

  @Input()
  formControlName!: string;

  @Input()
  formControl!: FormControl;

  @Input()
  disabled!: boolean;

  countries: Country[] = [];

  selectedCountry!: Country;

  nigeria = Countries.nigeria;

  constructor(
    private countriesService: CountriesService,
    @Self() @Optional() public ngControl: NgControl
  ) {
    if (this.ngControl) {
      this.ngControl.valueAccessor = NOOP_VALUE_ACCESSOR;
    }

    this.selectedCountry = this.nigeria;
  }

  get inputType(): string {
    switch (this.type) {
      case 'email':
        return 'email';
      case 'phone':
        return 'tel';
      case 'number':
        return 'tel';
      default:
        return 'text';
    }
  }

  onValueChange() {}
}
