import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class IconRegistryService {
  constructor(
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer
  ) {}

  setIcons() {
    this.iconRegistry.addSvgIcon(
      'success',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        'assets/images/success-icon.svg'
      )
    );
    this.iconRegistry.addSvgIcon(
      'time',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        'assets/images/time-icon.svg'
      )
    );
    this.iconRegistry.addSvgIcon(
      'message',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/images/message.svg')
    );
    this.iconRegistry.addSvgIcon(
      'info',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/images/info.svg')
    );
    this.iconRegistry.addSvgIcon(
      'copy',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/images/copy.svg')
    );
    this.iconRegistry.addSvgIcon(
      'chevron-left',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        'assets/images/chevron-left.svg'
      )
    );
  }
}
