<!-- <mat-form-field
  [appearance]="appearance"
  class="bg-textInput"
  [subscriptSizing]="subscriptSizing">
  <mat-select
    [placeholder]="placeholder"
    [compareWith]="compareFn"
    (selectionChange)="onSelectionChange($event)"
    [ngModel]="selectedOption"
    (ngModelChange)="searchAccounts($event)"
    class="bg-transparent placeholder-primary">
    <mat-select-trigger *ngIf="selectedOption">{{
      selectedOption ? selectedOption.accountNo : ''
    }}</mat-select-trigger>
    <mat-option>
      <ngx-mat-select-search
        appNumericInput
        [formControl]="searchControl"
        [placeholderLabel]="placeholderLabel"
        noEntriesFoundLabel=""
        (keydown.enter)="selectOption()"></ngx-mat-select-search>
    </mat-option>
    <div [ngClass]="showEnteredNumberOption ? 'block' : 'hidden'">
      <mat-option (click)="selectOption()" [value]="selectedOption">
        <div>{{ searchControl.value }}</div>
      </mat-option>
    </div>
    <mat-option *ngFor="let option of accountInfos" [value]="option">
      <div class="flex w-full justify-between">
        <div>{{ option.accountNo }}</div>
        <div>{{ option.accountType | titlecase }}</div>
      </div>
      <div class="flex w-full justify-between">
        <div class="font-bold">{{ option.accountName | titlecase }}</div>
        <div>{{ option.phoneNumber }}</div>
      </div>
      <hr class="w-full pt-2" />
    </mat-option>
    <mat-option *ngIf="accountInfos.length !== 0" (click)="_deleteHistory()">
      <p class="text-center text-sm text-primary-500">Clear history</p>
    </mat-option>
  </mat-select>
</mat-form-field> -->

<form [formGroup]="form">
  <div class="flex w-full items-center rounded border bg-textInput">
    <!-- Input -->
    <div class="ml-2 flex flex-grow items-center p-4" #textInputContainer>
      <input
        #textInput
        inputmode="numeric"
        pattern="[0-9]*"
        [title]="title"
        class="block w-full appearance-none rounded border border-none bg-transparent focus:border-transparent focus:outline-none"
        [placeholder]="placeholder"
        formControlName="search" />
      <mat-icon (click)="_toggleDropdownMenu()" class="text-primary-500">{{
        dropdownIcon
      }}</mat-icon>
    </div>
    <mat-menu #menu="matMenu" class="w-full max-w-none" xPosition="after">
      <div [ngStyle]="dropdownWidth" class="max-h-[150px]">
        <button type="button" mat-menu-item *ngFor="let option of accountInfos">
          <div class="px-3 py-1" (click)="selectOption(option)">
            <div class="flex w-full justify-between">
              <div>{{ option.accountNo }}</div>
              <div>{{ option.accountType | titlecase }}</div>
            </div>
            <div class="flex w-full justify-between">
              <div class="font-bold">{{ option.accountName | titlecase }}</div>
              <div>{{ option.phoneNumber }}</div>
            </div>
            <hr class="w-full pt-2" />
          </div>
        </button>
        <div mat-menu-item (click)="_deleteHistory()">
          <p
            *ngIf="accountInfos.length !== 0"
            class="text-center text-sm text-primary-500">
            Clear history
          </p>
          <p
            *ngIf="accountInfos.length === 0"
            class="text-center text-sm text-black">
            Recent accounts will appear here
          </p>
        </div>
      </div>
    </mat-menu>
  </div>
  <div
    #menuTrigger
    class="w-full border border-none"
    [matMenuTriggerFor]="menu"></div>
</form>
