<div class="flex h-full flex-col">
  <div class="landing-bg relative w-full flex-grow overflow-y-scroll">
    <div class="relative left-4 top-4 flex-col md:absolute md:flex">
      <img
        src="assets/images/ie-logo.png"
        class="h-16 w-16"
        alt="Ikeja Electric Logo" />
    </div>
    <router-outlet></router-outlet>
  </div>
  <div class="flex w-full items-center bg-primary p-2 md:p-2">
    <p class="text-sm text-white">Powered by payS</p>
  </div>
</div>
