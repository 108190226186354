import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appNumericInput]',
})
export class NumericInputDirective {
  @HostListener('input', ['$event'])
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onInput(event: any) {
    const input = event.target as HTMLInputElement;
    // Remove non-digit characters
    const trimmedValue = input.value.replace(/\D/g, '');

    input.value = trimmedValue;
  }
}

@Directive({
  selector: '[appDecimalInput]',
})
export class DecimalInputDirective {
  @HostListener('input', ['$event'])
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onInput(event: any) {
    const input = event.target as HTMLInputElement;
    // Remove non-digit characters
    const trimmedValue = input.value.replace(/[^0-9.]/g, '');

    input.value = trimmedValue;
  }
}
