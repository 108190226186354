<div class="flex">
  <div class="pill-container flex-1 rounded-full p-2">
    <ul class="flex justify-around rounded-full">
      <li *ngFor="let tab of tabs; let i = index" class="flex-1">
        <a
          [class.bg-primary]="i === active"
          [class.text-white]="i === active"
          [class.text-primary]="i !== active"
          [@tabAnimation]="i === active ? 'active' : 'inactive'"
          class="block rounded-full px-4 py-2 text-center font-semibold transition-colors duration-300 ease-in-out"
          (click)="onTabSelect($event, i)"
          href="#">
          {{ tab }}
        </a>
      </li>
    </ul>
  </div>
</div>
