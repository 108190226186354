import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';

import { environment } from './environments/environment';

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.error(err));

// Hide console messages in production
if (environment.production) {
  window.console.log = () => {};
  window.console.error = () => {};
  window.console.warn = () => {};
}
