<div class="transparent-dialog-container fixed flex h-full w-full gap-2">
  <div class="h-100 w-100 flex flex-grow justify-center">
    <iframe
      #aipCheckoutIFrame
      class="flex-grow"
      title="aip-checkout"
      name="aip-checkout"
      [src]="urlSafe"
      (load)="updateIframeSrcUrl(iframeSrcData)"
      frameborder="0"
      allowfullscreen></iframe>
  </div>
</div>
